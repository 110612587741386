/**
 * Перечисление всех наших используемых api
 */
export enum API_NAMES {
  // Наш основной API, возвращает инфу о кластерах, авторах и прочем
  Peroxide = 'peroxide',
  // Дополнительный API, возвращает инфу о курсах валют и графиках
  Uranyl = 'uranyl',
  // Старый основной API, нужен только для получения инфы о горячей новости
  Coolstream = 'coolstream',
  // Дополнительный API проекта Рамблер/Главная, возвращает инфу для виджетов
  Rambler = 'rambler',
  // Дополнительный API проекта Рамблер/Id, возвращает информацию о залогиненном пользователе
  Id = 'id',
  // Второй основной API, возвращает новости, рекомендованные для текущего пользователя
  Recommend = 'recommend',
  // Дополнительный API, возвращает новости источника кластера
  RecommendPlus = 'recommendPlus',
  // Дополнительный API проекта Рамблер/Комментарии, возвращает инфу о комментариях
  Comments = 'comments',
  // Дополнительный старый API, возвращал информацию о матчах
  Winline = 'winline',
  // Дополнительный API проекта Гороскопы
  Horoscopes = 'horoscopes',
  // Дополнительный API прокси кулстрима для вертикали путешествий
  Travel = 'travel',
}

export enum CACHE_TIME {
  Infinity = 0,
  // 1 час
  H1 = 3600,
  // 12 часов
  H12 = 43200,
  // 24 часов
  H24 = 86400,
  // 1 минута
  M1 = 60,
  // 2 минуты
  M2 = 120,
  // 4 минуты
  M4 = 240,
  // 5 минуты
  M5 = 300,
  // 10 минут
  M10 = 600,
  // 20 минут
  M20 = 1200,
  // 30 минут
  M30 = 1800,
  // 30 секунд
  S30 = 30,
}
