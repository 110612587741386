import fetch from 'isomorphic-fetch';

import { config } from 'config';
import { HTTP_STATUS } from 'config/common/httpStatusCodes';
import { sendRequestError } from 'server/collectors/prometheus/utils/metricsBatch/utils';
import { TimeoutError } from 'utils/promiseTimeout';
import { proxyfy } from 'utils/proxyFetcher/proxyfy';

import { requestHandler } from '../requestHandler';

import {
  GetDataPropsType,
  RequestError,
  RequestType,
  ResponseType,
  RESPONSE_TRANSFORM,
} from './typings';

const { API_TIMEOUT } = config;

/**
 * Метод фетча с необходимыми обертками
 * @param props - пропсы
 * @param props.api - объект работы с апишкой. Для каждой ручки можно переопределить домен запроса и таймаут
 * @param props.api.url - url апишки
 * @param props.api.clientTimeout - таймаута запроса с клиента
 * @param props.api.timeout - таймаута запроса
 * @param props.path - урл запроса. Может выступать в роли entrypoint, но только при условии, что урл не динамический
 * @param props.entrypoint - точка сбора метрик
 * @param props.cacheTime - время кэширования ручки в памяти приложения, в секундах.
 *  Значение 0 делает кэш постоянным.
 * @param props.updateTime - время обновления данных в закэшированной ручке.
 *  Значение 0 обновляет кэш при порче ручки.
 *  ВНИМАНИЕ. Автозапрос происходит ТОЛЬКО в зависимости по урлу.
 *  Куки, headers, body и любые другие параметры, которые могут меняться от запроса к запросу, меняться НЕ БУДУТ.
 *  При необходимости можно доработать механизм до поддержки большего колва данных.
 * @param props.responseTransform - имя метода для преобразования тела ответа
 * @param props.skipRedis - флаг пропуска redis
 * @param props.withLocalProxy - флаг для использования локальной прокси
 *   @see src/server/proxy/proxyMiddleware.ts
 * @param props.withRestrictionsIgnore - флаг, что для данного запроса будут игнорироваться любые запреты на запись.
 *  ВНИМАНИЕ. Настоятельно рекомендуется использовать этот флаг ТОЛЬКО для тех ручек, количество вариаций которых досттаточно малое.
 *  То есть вариантов ручек топов вертикалей будет не больше 30, а вот вариантов ручек кластеров - в теории больше четырех миллионов.
 *  Разумно предположить, что все подряд кластера складывать крайне нежелательно, иначе есть риск взорвать кэши.
 * @param props.withRelativePath - флаг что используется относительный урл (для ручек которые отваливаются по корсам на стейджах)
 * @param props.headers - заголовки запроса
 * @param props.method - метод запроса
 * @param props.body - тело запроса
 * @param props.signal - сигнал запроса
 */
export const getData = <T = any>({
  api: { url, clientTimeout, timeout },
  path,
  entrypoint,
  cacheTime,
  updateTime,
  responseTransform = RESPONSE_TRANSFORM.json,
  skipRedis = false,
  withLocalProxy = false,
  withRestrictionsIgnore = false,
  withRelativePath = false,
  headers,
  method = 'GET',
  body,
  signal,
  ...props
}: GetDataPropsType): Promise<ApiResponse<T>> => {
  const requestUrl =
    withRelativePath && !__DEV__ && __BROWSER__ ? path : `${url}${path}`;

  /**
   * Из-за того что вызовы с withLocalProxy по итогу работы подменяют entrypoint на path,
   * кэширование таких ручек в dev режиме может работать не стабильно. До тех пор, пока на стендах
   * эта настройка не активирована - особых проблем это не вызывает.
   */
  const fetchPath = withLocalProxy
    ? proxyfy({ url: requestUrl, entrypoint })
    : requestUrl;

  const apiTimeout =
    (__SERVER__ ? timeout : clientTimeout) ?? timeout ?? API_TIMEOUT;

  // Абортер на случай, если запрос отвалится
  const fetchAborter = new AbortController();

  // Отменять запрос если он не выполнился за заданное время
  const timeoutSignal = AbortSignal.timeout(apiTimeout);
  const signals = [timeoutSignal];

  if (signal) {
    signals.push(signal);
  }

  /**
   * Подробнее о том, что здесь происходит:
   * @see https://rashidshamloo.hashnode.dev/adding-timeout-and-multiple-abort-signals-to-fetch-typescriptreact
   * Если коротко, то это нужно для передачи нескольких сигналов
   * Существует метод any() @see https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/any_static
   * Когда матрица поддержки браузерами будет соответствовать, можно будет заменить
   * @see https://confluence.rambler-co.ru/pages/viewpage.action?pageId=39493001
   */
  signals.forEach((signal) => {
    if (signal.aborted) {
      fetchAborter.abort(signal.reason);
    }

    signal.addEventListener(
      'abort',
      () => {
        fetchAborter.abort(signal.reason);
      },
      // Передаем сигнал в опциях, чтобы событие удалялось при прерывании сигнала
      { signal: fetchAborter.signal },
    );
  });

  const request: RequestType = async () => {
    try {
      const response = (await fetch(fetchPath, {
        headers,
        method,
        body,
        signal: fetchAborter.signal,
        keepalive: true,
        ...props,
      })) as ResponseType;

      const status = response?.status || 0;

      // Собираем статистику по ошибкам, кроме 404 ответа от сервера
      if (
        (!response?.ok || response?.error) &&
        status !== HTTP_STATUS.NOT_FOUND
      ) {
        const message = response?.error?.message || 'Unknown error';

        const requestError = new RequestError(message, status, entrypoint);

        sendRequestError(requestError);

        throw requestError;
      }

      if (response?.status === HTTP_STATUS.NOT_FOUND) {
        throw new RequestError('Status error', status, entrypoint);
      }

      const [resBody, resInfo] = await Promise.all([
        response[responseTransform](),
        Promise.resolve({
          headers: response.headers,
          status: response.status,
          entrypoint,
        }),
      ]);

      return {
        data: resBody,
        headers: resInfo.headers,
        status: resInfo.status,
        entrypoint: resInfo.entrypoint,
      };
    } catch (error) {
      if (error instanceof TimeoutError) {
        return {
          error: new TimeoutError(`Timed out in ${apiTimeout} ms. in ${path}`)
            .message,
          status: error.status,
        };
      }

      if (error instanceof RequestError) {
        return {
          error: `Request error: ${path}`,
          status: error.status,
        };
      }

      return {
        error: 'Unknown error',
        status: 0,
      };
    }
  };

  return requestHandler({
    url,
    entrypoint,
    path,
    method,
    request,
    skipRedis,
    withRestrictionsIgnore,
    memoryCacheTime: cacheTime ?? undefined,

    /**
     * Дорогой коллега-программист,
     *  Если соберешься убирать условие ниже, надеюсь ты знаешь, что делаешь.
     *
     *  Ниже - защита от того, что авторефрешиться будут только те данные, которые
     *    зависят ТОЛЬКО от query параметра и метода.
     */
    memoryCacheUpdateTime: !body && !headers ? updateTime : undefined,
  });
};
