type GetDataFromMemoryCache = <T = object>(options: {
  key: string;
  entrypoint: string;
}) => T | null;

/**
 * ЗАГЛУШКА ДЛЯ КЛИЕНТА, @see getCache.server.ts
 * Функция получения данных из кэша памяти и сбора метрик по обращению к кэшу.
 */
export const getDataFromMemoryCache: GetDataFromMemoryCache = () => null;
