import { ERROR_TYPE } from 'server/typings';

export type GetDataPropsType = {
  api: Api;
  path: string;
  entrypoint: string;
  cacheTime?: number;
  updateTime?: number;
  responseTransform?: RESPONSE_TRANSFORM;
  skipRedis?: boolean;
  withLocalProxy?: boolean;
  withRestrictionsIgnore?: boolean;
  withRelativePath?: boolean;
  adtechData?: AdtechDataType;
} & RequestInit;

export type AdtechDataType = {
  uid: AdtechType['uid'];
  scope: AdtechType['scope'];
};

export type RequestType = () => Promise<ApiResponse>;

export type ResponseType = Response & { entrypoint: string; error: Error };

export enum RESPONSE_TRANSFORM {
  json = 'json',
  text = 'text',
}

/**
 * Класс ошибки запроса
 */
export class RequestError extends Error {
  constructor(message: string, status: number, entrypoint: string) {
    super(message);

    this.name = ERROR_TYPE.Request;
    this.status = status ?? 0;
    this.entrypoint = entrypoint ?? '';
  }

  status: number;

  entrypoint: string;
}
