/* eslint-disable sonarjs/no-duplicate-string */
import rubleData from '@mocks/api/currencies/rubleData.json';
import { RamblerGigaChatData } from 'common/components/RamblerComponents/RamblerGigaChat';
import { DefaultBankRegionsType } from 'common/redux/commonData/bankRegions/typings';
import { BANKS_RATES_CURRENCY } from 'common/redux/commonData/widgets/banksRatesWidget/typings';
import {
  FORECASTS_TYPE,
  ForecastsLastType,
} from 'common/redux/commonData/widgets/consensusWidget/typings';
import { ExchangeRateDynamicsCurrency } from 'common/redux/commonData/widgets/exchangeRateDynamicsWidget/typings';
import { CURRENCY_SOURCES } from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import { DEFAULT_REGION } from 'common/redux/commonData/widgets/exchangeWidget/constants';
import {
  IndicesAndQuotesCharCode,
  IndicesAndQuotesMarketsName,
  IndicesAndQuotesPeriod,
} from 'common/redux/commonData/widgets/indicesAndQuotesWidget/typings';
import { CLUSTER_TYPE_ALIAS } from 'config/common/cluster/typings';
import { CURRENCY_CHAR_CODE } from 'config/common/finance/typings';
import { PAGE_TYPE } from 'config/common/router/typings';
import { recommendationsAreLoadedOnServerHit } from 'server/collectors/prometheus';
import { getArrayQueryParams } from 'utils/getArrayQueryParams';
import { stringToHex } from 'utils/hexConverter/stringToHex';

import {
  getApiCardList,
  getApiTopicList,
  getApiCluster,
  getApiAutotagList,
  getApiClusterItem,
  getApiClusterItemsList,
  getApiAutotagPageInfo,
  getApiRegion,
  getApiRecommendedClustersList,
  getApiThemeInfo,
  getApiTagInfo,
  getVideoAd,
  getApiExpert,
  getApiClusterRelated,
  getApiAutotagPopularList,
  getApiCurrencyData,
  getApiCurrenciesRates,
  getApiExchangeRatesForecast,
  getApiTravelRegions,
  getApiTravelPlaces,
  getApiTravelRegionInfo,
} from './adapters';
import { getData } from './getData';
import { AdtechDataType } from './getData/typings';
import { API_NAMES, CACHE_TIME } from './typings';
import { GetBankObjectsOptionsType } from './typings/common';
import {
  getNextPage,
  getUserAgentStr,
  getRCMData,
  getAPIFromConfig,
} from './utils';

type ClusterIdType = string | null;

/**
 * Данные о медиа-проектах.
 * @param apiConfig - набор конфигов api.
 */
export const getProjects = async (apiConfig: ApiConfigType) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: '/v1/projects/',
    entrypoint: '/v1/projects/',
  });

  return { ...response };
};

/**
 * Данные по одному проекту.
 * @param apiConfig - набор конфигов api;
 * @param projectId - ID проекта(вертикали).
 */
export const getProject = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/`,
    entrypoint: '/v1/projects/{projectId}/',
  });

  return { ...response };
};

/**
 * Данные по двум валютам (euro и usd): актуальные курсы из трех источников, прогноз и выгодный курс обмена.
 * @param apiConfig - набор конфигов api;
 */
export const getRateExchangeForecast = async (apiConfig: ApiConfigType) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: '/v1/rate_exchange_forecast/',
    entrypoint: '/v1/rate_exchange_forecast/',
    withLocalProxy: true,
    cacheTime: CACHE_TIME.M2,
  });

  const data = response.data || {};
  const currenciesNames = Object.keys(data);
  const currenciesData = Object.values(data) as CurrencyExchangeRatesForecast[];

  return {
    ...response,
    data: currenciesData.map((currency, index) =>
      getApiExchangeRatesForecast(currenciesNames[index], currency),
    ),
  };
};

/**
 * Информация обо всех валютах из Центробанка.
 * @param apiConfig - набор конфигов api.
 */
export const getCurrenciesDataFromCbr = async (apiConfig: ApiConfigType) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: '/v1/sources/cbr/instruments/',
    entrypoint: '/v1/sources/cbr/instruments/',
    withLocalProxy: true,
    cacheTime: CACHE_TIME.M2,
  });

  const fullData = [...response.data, rubleData];

  return { ...response, data: fullData.map(getApiCurrencyData) };
};

/**
 * Информация о котировках всех валют из Центробанка.
 * Без указания времени, в values будут котировки за ближайшие 3 дня.
 * @param props - пропсы
 * @param props.apiConfig - набор конфигов api;
 * @param props.start - начало временного отрезка, за который запрашиваем информацию (в секундах);
 * @param props.end - конец временного отрезка (в секундах).
 */
export const getCurrenciesRatesFromCbr = async ({
  apiConfig,
  start,
  end,
}: {
  apiConfig: ApiConfigType;
  start?: number;
  end?: number;
}) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/sources/cbr/instruments/${
      start && end ? `?start=${start}&end=${end}` : ''
    }`,
    entrypoint: '/v1/sources/cbr/instruments/',
    withLocalProxy: true,
    cacheTime: CACHE_TIME.M2,
  });

  return { ...response, data: getApiCurrenciesRates(response.data) };
};

/**
 * Информация о конкретной валюте из Центробанка.
 * @param props - пропсы
 * @param props.apiConfig - набор конфигов api;
 * @param props.charCode - код валюты, о которой хотим получить информацию.
 */
export const getCurrencyDataFromCbr = async ({
  apiConfig,
  charCode,
}: {
  apiConfig: ApiConfigType;
  charCode: string;
}) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/sources/cbr/instruments/${charCode}/`,
    entrypoint: '/v1/sources/cbr/instruments/{charCode}/',
    withLocalProxy: true,
    cacheTime: CACHE_TIME.M2,
  });

  return { ...response, data: getApiCurrencyData(response.data?.[0]) };
};

const COMMON_CURRENCY_TO_SPECIFIC: Record<string, Record<string, string>> = {
  [CURRENCY_SOURCES.Forex]: {
    [CURRENCY_CHAR_CODE.USD]: 'USDRUB',
    [CURRENCY_CHAR_CODE.EUR]: 'EURRUB',
  },
  [CURRENCY_SOURCES.MMCB]: {
    [CURRENCY_CHAR_CODE.USD]: 'USD000UTSTOM',
    [CURRENCY_CHAR_CODE.EUR]: 'EUR_RUB__TOM',
  },
};

/**
 * Информация о котировке конкретной валюты из внешних источников.
 * Без указания времени, в values будут котировки за ближайшие 3 дня.
 * @param props - пропсы
 * @param props.apiConfig - набор конфигов api;
 * @param props.source - специальный источник;
 * @param props.charCode - код валюты, о которой хотим получить информацию;
 * @param props.start - начало временного отрезка, за который запрашиваем информацию (в секундах);
 * @param props.end - конец временного отрезка (в секундах).
 */
export const getCurrencyRatesFromOuterSources = async ({
  apiConfig,
  source = CURRENCY_SOURCES.Centrobank,
  charCode,
  start,
  end,
}: {
  apiConfig: ApiConfigType;
  source?: CURRENCY_SOURCES;
  charCode: string;
  start?: number;
  end?: number;
}) => {
  // Для валюты рубля данные добавляются вручную
  if (charCode === CURRENCY_CHAR_CODE.RUB) {
    return {
      data: {} as FullCurrencyRatesType,
      error: null,
    };
  }

  const fixedCharCode =
    COMMON_CURRENCY_TO_SPECIFIC?.[source]?.[charCode] ?? charCode;

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),

    /**
     * {source === CURRENCY_SOURCES.MMCB ? 'moex' : source} буквально присутствует в старом коде.
     * Увы, данные мы получаем как от bcs, но запрашивать от бека их надо как из moex, при том, что
     *  для клиента moex и bcs имеют одинаковые обозначения.
     */
    path: `/v1/sources/${
      source === CURRENCY_SOURCES.MMCB ? 'moex' : source
    }/instruments/${fixedCharCode}/${
      start && end ? `?start=${start}&end=${end}` : ''
    }`,
    entrypoint: '/v1/sources/{source}/instruments/{charCode}/',
    withLocalProxy: true,
    cacheTime: CACHE_TIME.M2,
  });

  return { ...response, data: getApiCurrenciesRates(response.data) };
};

/**
 * Курсы валют.
 * @param apiConfig - набор конфигов api;
 * @param rate - тип финансовой статистики.
 */
export const getExchangeRates = async (
  apiConfig: ApiConfigType,
  rate: RateType,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/exchange-rates/${rate}/`,
    entrypoint: '/v1/exchange-rates/{rate}/',
    withLocalProxy: true,
    // Ручка меняется не так часто
    cacheTime: CACHE_TIME.M30,
  });

  return { ...response };
};

/**
 * Топики по всем проектам(вертикалям).
 * @param apiConfig - набор конфигов api.
 * @param timeout - таймаут
 */
export const getAllTopics = async (
  apiConfig: ApiConfigType,
  timeout?: number,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide, timeout }),
    path: '/v1/topics/',
    entrypoint: '/v1/topics/',
  });

  return { ...response, data: getApiTopicList(response.data) };
};

/**
 * Топики по выбранной вертикали.
 * @param apiConfig - набор конфигов api;
 * @param projectId - ID проекта(вертикали).
 */
export const getTopics = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/topics/`,
    entrypoint: '/v1/projects/{projectId}/topics/',
    withLocalProxy: true,
  });

  return { ...response, data: getApiTopicList(response.data) };
};

type GetNewsByTopicPropsType = {
  apiConfig: ApiConfigType;
  topicId: number;
  onlyMainTopic?: boolean;
  page?: number;
  limit?: number;
};

/**
 * Кластера по топику.
 * @param props - пропсы
 * @param props.apiConfig - набор конфигов api;
 * @param props.topicId - ID топика(рубрики);
 * @param props.onlyMainTopic - получение кластеров, для которых топик является главным (по умолчанию true);
 * @param props.page - номер страницы;
 * @param props.limit - лимит количества новостей;
 */
export const getNewsByTopic = async ({
  apiConfig,
  topicId,
  onlyMainTopic = true,
  page = 1,
  limit = 15,
}: GetNewsByTopicPropsType) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/topics/${topicId}/tops/topic/clusters/?limit=${limit}&page=${page}${
      onlyMainTopic ? '' : '&only_main_topic=false'
    }`,
    entrypoint: '/v1/topics/{topicId}/tops/topic/clusters/',
    withLocalProxy: true,
    // Ручка достаточно часто переключается
    cacheTime: CACHE_TIME.M4,
  });

  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Топы кластеров по всем вертикалям.
 * @param apiConfig - набор конфигов api;
 * @param topID - тип запрашиваемого топа;
 * @param limit - количество загружаемых новостей.
 */
export const getTops = async (
  apiConfig: ApiConfigType,
  topID: TopGeneralType,
  limit?: number,
) => {
  const queryString = limit ? `?limit=${limit}` : '';

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/tops/${topID}/clusters/${queryString}`,
    entrypoint: '/v1/tops/{topID}/clusters/',
    withLocalProxy: true,
    // Ручка очень часто меняет содержимое
    cacheTime: CACHE_TIME.M1,
  });

  return { ...response, data: getApiCardList(response.data) };
};

/**
 * Топы кластеров по выбранной вертикали и топику.
 * @param apiConfig - набор конфигов api;
 * @param projectId - ID проекта(вертикали);
 * @param topID - тип запрашиваемого топа;
 * @param limit - лимит количества кластеров.
 */
export const getTopsByProject = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
  topID: TopType,
  limit: number = 20,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/tops/${topID}/clusters/?limit=${limit}`,
    entrypoint: '/v1/projects/{projectId}/tops/{topID}/clusters/',
    withLocalProxy: true,
    withRestrictionsIgnore: true,
    // Бесконечнось. Кэш этой ручки нельзя вычищать
    cacheTime: CACHE_TIME.Infinity,
    updateTime: CACHE_TIME.M1,
  });

  return { ...response, data: getApiCardList(response.data) };
};

/**
 * Получить данные о кластеру по clusterId.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getCluster = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/`,
    entrypoint: '/v1/clusters/{clusterId}/',
    withLocalProxy: true,
    // Кол-во комментов на странице рубрики и странице кластера должно не сильно разниться
    cacheTime: CACHE_TIME.M2,
  });

  return { ...response, data: getApiCluster(response.data) };
};

/**
 * Получить источник кластера по clusterId.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getClusterResource = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData<ClusterResourceType>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/resource/`,
    entrypoint: '/v1/clusters/{clusterId}/resource/',
    withLocalProxy: true,
    // Кластер меняется не так уж и часто
    cacheTime: CACHE_TIME.M10,
  });

  return { ...response };
};

/**
 * Получить автотеги кластера по clusterId.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getClusterAutotags = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/autotags/`,
    entrypoint: '/v1/clusters/{clusterId}/autotags/',
    withLocalProxy: true,
    // Автотеги могут проставиться совсем не те
    cacheTime: CACHE_TIME.M5,
  });

  return { ...response, data: getApiAutotagList(response.data) };
};

/**
 * Получить ручные теги кластера по clusterId.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getClusterTags = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData<APITagInfo[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/tags/`,
    entrypoint: '/v1/clusters/{clusterId}/tags/',
    withLocalProxy: true,
    // Теги могут проставиться совсем не те
    cacheTime: CACHE_TIME.M5,
  });

  return { ...response };
};

/**
 * Последние новости от источника кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getClusterRelated = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/related/items/`,
    entrypoint: '/v1/clusters/{clusterId}/related/items/',
    withLocalProxy: true,
    // Кластер меняется не так уж и часто
    cacheTime: CACHE_TIME.M10,
  });

  return { ...response, data: getApiClusterRelated(response.data) };
};

/**
 * Список IFrames.
 * @param apiConfig - набор конфигов api.
 */
export const getIFramesList = async (apiConfig: ApiConfigType) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: '/v1/advertisements/',
    entrypoint: '/v1/advertisements/',
  });

  return { ...response, data: getVideoAd(response.data) };
};

/**
 * Получить тематические и форматные рубрики кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getClusterTopics = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData<ClusterTopicsType[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/topics/`,
    entrypoint: '/v1/clusters/{clusterId}/topics/',
    withLocalProxy: true,
    // Топики могут проставиться совсем не те
    cacheTime: CACHE_TIME.M5,
  });

  return { ...response };
};

/**
 * Получить сюжеты кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getClusterStories = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData<APIThemeInfo[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/stories/`,
    entrypoint: '/v1/clusters/{clusterId}/stories/',
    withLocalProxy: true,
    cacheTime: CACHE_TIME.M5,
  });

  return { ...response };
};

/**
 * Список авторов.
 * @param apiConfig - набор конфигов api;
 * @param timeout - таймаут на выполнение запроса на сервере;
 * @param clientTimeout - таймаут на выполнение запроса на клиенте.
 */
export const getEditors = async (
  apiConfig: ApiConfigType,
  timeout?: number,
  clientTimeout?: number,
) => {
  const response = await getData({
    api: getAPIFromConfig({
      apiConfig,
      apiName: API_NAMES.Peroxide,
      timeout,
      clientTimeout,
    }),
    path: '/v1/authors/',
    entrypoint: '/v1/authors/',
  });

  return { ...response };
};

/**
 * Список авторов по вертикале.
 * @param apiConfig - набор конфигов api;
 * @param projectId - ID вертикали, по которой необходим список.
 */
export const getEditorsByProject = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/authors/`,
    entrypoint: '/v1/projects/{projectId}/authors/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Данные об авторе по его алиасу.
 * @param apiConfig - набор конфигов api;
 * @param editorAlias - алиас редактора, по которому выдадутся новости.
 */
export const getEditor = async (
  apiConfig: ApiConfigType,
  editorAlias: EditorType['alias'],
) => {
  const response = await getData<FullEditorType>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/authors/${editorAlias}/`,
    entrypoint: '/v1/authors/{editorAlias}/',
    // Редактор вообще редко меняется
    cacheTime: CACHE_TIME.H1,
  });

  return { ...response };
};

/**
 * Получить редактора для кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getEditorForCluster = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData<EditorType>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/author/`,
    entrypoint: '/v1/clusters/{clusterId}/author/',
    withLocalProxy: true,
    // Редактор у кластера не часто меняется и его не надо будет менять критично
    cacheTime: CACHE_TIME.M20,
  });

  return { ...response };
};

type GetAutotagNewsByProjectType = {
  apiConfig: ApiConfigType;
  projectId: ProjectType['id'];
  autotagType: ATAutotag['autotagType'];
  autotagAlias: ATAutotag['alias'];
  clusterTypes: CLUSTER_TYPE_ALIAS[];
  page?: number;
  limit?: number;
  dateFrom?: string | null;
  dateTo?: string | null;
  timeout?: number;
  clientTimeout?: number;
};

/**
 * Список новостей по автотегу.
 * @param props - пропсы
 * @param props.apiConfig - набор конфигов api;
 * @param props.projectId - ID вертикали, по которой необходим список;
 * @param props.autotagType - тип автотега;
 * @param props.autotagAlias - алиас автотега;
 * @param props.clusterTypes - типы кластеров для отдачи;
 * @param props.limit - лимит количества новостей;
 * @param props.page - номер страницы;
 * @param props.dateFrom - Начало периода за который нужны новости (UTC);
 * @param props.dateTo - Конец периода (UTC);
 * @param props.timeout - опциональный параметр для настройки таймаута;
 * @param props.clientTimeout - опциональный параметр для настройки таймаута на стороне клиента.
 */
export const getAutotagNewsByProject = async ({
  apiConfig,
  projectId,
  autotagType,
  autotagAlias,
  clusterTypes,
  page = 1,
  limit = 15,
  dateFrom,
  dateTo,
  timeout,
  clientTimeout,
}: GetAutotagNewsByProjectType) => {
  const params = getArrayQueryParams('cluster_types', clusterTypes);

  const response = await getData({
    api: getAPIFromConfig({
      apiConfig,
      apiName: API_NAMES.Peroxide,
      timeout,
      clientTimeout,
    }),
    path: `/v1/projects/${projectId}/autotags/${autotagType}/${autotagAlias}/clusters/?${params}&limit=${limit}&page=${page}${
      dateFrom && dateTo ? `&date_from=${dateFrom}&date_to=${dateTo}` : ''
    }`,
    entrypoint:
      '/v1/projects/{projectId}/autotags/{autotagType}/{autotagAlias}/clusters/',
    withLocalProxy: true,
    // Ручка по кластерам автотегов меняется не так часто, а ещё достаточно тяжелая
    cacheTime: CACHE_TIME.M20,
  });

  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Список latest новостей вертикали.
 * Также может отдавать последние новости за определенную дату, если добавить нужный queryParam
 * @param apiConfig - набор конфигов api;
 * @param projectId - ID вертикали, по которой необходим список;
 * @param page - номер страницы;
 * @param limit - лимит количества новостей;
 * @param date - дата за которую нужны новости (YYYY-MM-DD).
 * @param signal - сигнал аборта запрос.
 */
export const getLatestNewsByProject = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
  page: number = 1,
  limit: number = 15,
  date?: string,
  signal?: AbortSignal,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/clusters/?limit=${limit}&page=${page}${
      date ? `&date=${date}` : ''
    }`,
    entrypoint: '/v1/projects/{projectId}/clusters/',
    withLocalProxy: true,
    // Данные для непопулярного топа не всегда нужны актуальные
    cacheTime: CACHE_TIME.M10,
    signal,
  });

  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Рейтинг персоны или организации.
 * @param apiConfig - набор конфигов api;
 * @param projectId - ID вертикали, по которой необходим рейтинг;
 * @param autotagType - тип автотега;
 * @param autotagAlias - алиас автотега.
 */
export const getAutotagRating = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
  autotagType: ATAutotag['autotagType'],
  autotagAlias: ATAutotag['alias'],
) => {
  const response: ApiResponse<APIAutotagRating> = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/autotags/${autotagType}/${autotagAlias}/rating/`,
    entrypoint:
      '/v1/projects/{projectId}/autotags/{autotagType}/{autotagAlias}/rating/',
    withLocalProxy: true,
    // Ручка не тяжелая и меняется быстро
    cacheTime: CACHE_TIME.M5,
  });

  return { ...response, data: response.data };
};

/**
 * Детальная информация по автотегу.
 * @param apiConfig - набор конфигов api;
 * @param autotagType - тип автотега;
 * @param autotagAlias - алиас автотега;
 * @param timeout - опциональный параметр для настройки таймаута на стороне сервера.
 */
export const getAutotagPageInfo = async (
  apiConfig: ApiConfigType,
  autotagType: ATAutotag['autotagType'],
  autotagAlias: ATAutotag['alias'],
  timeout?: number,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide, timeout }),
    path: `/v1/autotags/${autotagType}/${autotagAlias}/`,
    entrypoint: '/v1/autotags/{autotagType}/{autotagAlias}/',
    withLocalProxy: true,
    // Ручка не тяжелая и меняется быстро
    cacheTime: CACHE_TIME.M5,
  });

  return { ...response, data: getApiAutotagPageInfo(response.data) };
};

/**
 * Список новостей по автору/редактору.
 * @param apiConfig - набор конфигов api;
 * @param projectId - ID вертикали, по которой необходим список;
 * @param editorAlias - алиас редактора, по которому выдадутся новости.
 */
export const getEditorNewsByProject = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
  editorAlias: EditorType['alias'],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/authors/${editorAlias}/clusters/`,
    entrypoint: '/v1/projects/{projectId}/authors/{editorAlias}/clusters/',
    // Ручка не меняется так часто
    cacheTime: CACHE_TIME.M20,
  });

  return { ...response, data: getApiCardList(response.data) };
};

/**
 * Данные об эксперте по его алиасу.
 * @param apiConfig - набор конфигов api;
 * @param expertAlias - алиас эксперта.
 */
export const getExpert = async (
  apiConfig: ApiConfigType,
  expertAlias: ATExpertType['alias'],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/experts/${expertAlias}/`,
    entrypoint: '/v1/experts/{expertAlias}/',
    // Ручка не меняется так часто
    cacheTime: CACHE_TIME.M20,
  });

  return { ...response, data: getApiExpert(response.data) };
};

/**
 * Список новостей по эксперту.
 * @param apiConfig - набор конфигов api;
 * @param expertId - ID эксперта;
 * @param params - параметры запроса.
 * @param params.isActual - флаг актуальности эксперта
 * @param params.limit - лимит количества новостей
 * @param params.page - номер страницы
 */
export const getExpertNewsByProject = async (
  apiConfig: ApiConfigType,
  expertId: ATExpertType['id'],
  params: {
    isActual?: string;
    limit?: number;
    page?: number;
  } = {},
) => {
  const { page = 1, limit = 50, isActual = 'All' } = params;

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/experts/${expertId}/clusters/?page=${page}&limit=${limit}&is_actual=${isActual}`,
    entrypoint: '/v1/projects/{expertId}/clusters/',
    // Ручка не меняется так часто
    cacheTime: CACHE_TIME.M20,
  });

  return {
    ...response,
    data: response.data ? getApiCardList(response.data) : [],
  };
};

/**
 * Получить детальную информацию об нескольких экспертах.
 * @param apiConfig - набор конфигов api;
 * @param expertIDs - id экспертов, по которым получается информация.
 */
export const getExpertsForCluster = async (
  apiConfig: ApiConfigType,
  expertIDs: number[],
) => {
  const queryString = expertIDs.map((id) => `id[]=${id}`).join('&');

  const response: ApiResponse<APIExpertType[]> = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/experts/full/?${queryString}`,
    entrypoint: '/v1/experts/full/',
    // Данные для кластера могут меняться достаточно часто
    cacheTime: CACHE_TIME.M5,
  });

  return {
    ...response,
    data: response.data?.map ? response.data.map(getApiExpert) : [],
  };
};

/**
 * Получить новости источников для кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getClusterItems = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/items/`,
    entrypoint: '/v1/clusters/{clusterId}/items/',
    withLocalProxy: true,
    // Данные для кластера могут меняться достаточно часто
    cacheTime: CACHE_TIME.M5,
  });

  return { ...response, data: getApiClusterItemsList(response.data) };
};

/**
 * Получить главный источник для кластера.
 * @param apiConfig - набор конфигов api;
 * @param clusterId - id желаемого кластера.
 */
export const getClusterMainItem = async (
  apiConfig: ApiConfigType,
  clusterId: ClusterIdType,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/clusters/${clusterId}/item/`,
    entrypoint: '/v1/clusters/{clusterId}/item/',
    withLocalProxy: true,
    // Данные для кластера могут меняться достаточно часто
    cacheTime: CACHE_TIME.M5,
  });

  return { ...response, data: getApiClusterItem(response.data) };
};

/**
 * Получить инфо о регионе.
 * @param apiConfig - набор конфигов api;
 * @param regionAlias - alias региона.
 */
export const getRegionInfo = async (
  apiConfig: ApiConfigType,
  regionAlias: ATRegion['alias'],
) => {
  const response = await getData<APIRegion>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/regions/${regionAlias}/`,
    entrypoint: '/v1/regions/{regionAlias}/',
    // Ручка не меняется так часто
    cacheTime: CACHE_TIME.M20,
  });

  return { ...response, data: getApiRegion(response.data) };
};

/**
 * Получить кластеры по региону
 * @param apiConfig - набор конфигов api;
 * @param projectId - id вертикали;
 * @param regionAlias - alias региона;
 * @param page - номер страницы;
 * @param limit - лимит количества новостей.
 */
export const getRegionClusters = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
  regionAlias: ATRegion['alias'],
  page: number = 1,
  limit: number = 20,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/regions/${regionAlias}/clusters/?limit=${limit}&page=${page}`,
    entrypoint: '/v1/projects/{projectId}/regions/{regionAlias}/clusters/',
    // Ручка кластеров меняется достаточно часто
    cacheTime: CACHE_TIME.M5,
  });

  return { ...response, data: getApiCardList(response.data) };
};

type GetRecommendedClusterPropsType = {
  blockId: string;
  xuid: string;
  itemId: number | string | null;
  limit?: number;
  itemExcludedIds?: Card['id'][];
  userId: string;
  isBot: boolean;
  apiConfig: ApiConfigType;
  sessionID?: string;
  pageName?: string;
  clientTimeout?: number;
  category?: string;
  locationName?: string | null;
  adtechData?: AdtechDataType;
};

/**
 * Получить массив рекомендованных кластеров для бесконечного скролла
 * @see https://recommender.um.rambler.ru/docs/api.html
 * @param props - пропсы
 * @param props.apiConfig - набор конфигов api;
 * @param props.blockId - id блока в зависимости от окружения (RECOMMEND_BLOCK_ID);
 * @param props.xuid - значение куки ruid для проставления в xuid;
 * @param props.itemId - id текущего кластера;
 * @param props.limit - лимит количества возвращаемых id;
 * @param props.itemExcludedIds - список id кластеров, которые будут исключены из выдачи рекоммендаций;
 * @param props.userId - id пользователя из куки rccid;
 * @param props.sessionID - id сесси общения с рекомендами;
 * @param props.isBot - флаг, что это бот делает запрос;
 * @param props.pageName - имя страницы на которой происходит запрос;
 * @param props.category - топик, для которого получаются рекомендации (если такой есть);
 * @param props.locationName - текущее расположение пользователя;
 * @param props.adtechData - данные для adtech.
 */
export const getRecommendedClusters = async ({
  apiConfig,
  blockId,
  xuid,
  itemId = 0,
  limit = 10,
  itemExcludedIds = [],
  userId,
  sessionID,
  pageName,
  isBot,
  category,
  locationName,
  adtechData,
}: GetRecommendedClusterPropsType) => {
  const hexRuid = xuid ? stringToHex(xuid) : '';
  const encodeXuid = xuid ? encodeURIComponent(xuid) : '';
  const itemIdStr = itemId ? `&item_id=${itemId}` : '';
  const userIdStr = userId ? `&user_id=${userId}` : '';
  const sessionIdStr = sessionID ? `&session_id=${sessionID}` : '';
  const isBotStr = isBot !== undefined ? `&isBot=${!!isBot}` : '';

  const categoryStr = category
    ? `&category=${encodeURIComponent(category)}`
    : '';

  const regionStr = locationName
    ? `&region=${encodeURIComponent(locationName)}`
    : '';

  const hasParams: boolean = Boolean(blockId && hexRuid && encodeXuid);

  if (!hasParams) {
    if (__DEV__) {
      console.warn(
        'Отсутствует какой-то из параметров blockId, ruid, xuid или item_id',
      );
    }

    return {
      error:
        'Отсутствует какой-то из параметров blockId, ruid, xuid или item_id',
      data: null,
    };
  }

  const path = `/api/v2/blocks/${blockId}/recommendations?ruid=${hexRuid}&xuid=${encodeXuid}${itemIdStr}${userIdStr}&limit=${limit}${sessionIdStr}${getUserAgentStr()}${isBotStr}${categoryStr}${regionStr}`;

  let headers = {};
  let body = {};

  const recomendFeatures: string[] = [];
  const pagesWithUseSessionID = [PAGE_TYPE.home, PAGE_TYPE.topic];

  if (itemExcludedIds.length) {
    recomendFeatures.push('item_excluded_ids');

    const uniqItemExcludedIds = new Set(itemExcludedIds.map(String));

    body = {
      item_excluded_ids: Array.from(uniqItemExcludedIds),
    };
  }

  if (
    pagesWithUseSessionID.some((allowPageName) => allowPageName === pageName)
  ) {
    recomendFeatures.push('exclude_session_items');
  }

  headers = {
    'Recommender-Features': recomendFeatures.join(', '),
  };

  if (__SERVER__) {
    recommendationsAreLoadedOnServerHit(isBot, blockId);
  }

  const response = await getRCMData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Recommend }),
    path,
    entrypoint: '/api/v2/blocks/{blockId}/recommendations/',
    withLocalProxy: true,
    adtechData,
    headers,
    method: 'POST',
    body: JSON.stringify(body),
  });

  return {
    ...response,
    data: getApiRecommendedClustersList(response.data),
  };
};

/**
 * Получить инфо о сюжете
 * @param apiConfig - набор конфигов api;
 * @param themeId - id сюжета.
 */
export const getThemeInfo = async (
  apiConfig: ApiConfigType,
  themeId: string | number,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/stories/${themeId}/`,
    entrypoint: '/v1/stories/{themeId}/',
    withLocalProxy: true,
    // Ручка не меняется так часто
    cacheTime: CACHE_TIME.M20,
  });

  return { ...response, data: getApiThemeInfo(response.data) };
};

/**
 * Получить новости сюжета.
 * @param apiConfig - набор конфигов api;
 * @param themeId - id сюжета;
 * @param page - номер страницы;
 * @param limit - лимит количества новостей.
 */
export const getThemeNews = async (
  apiConfig: ApiConfigType,
  themeId: string | number,
  page: number = 1,
  limit: number = 15,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/stories/${themeId}/clusters/?limit=${limit}&page=${page}`,
    entrypoint: '/v1/stories/{themeId}/clusters/',
    withLocalProxy: true,
    // Список кластеров достаточно изменчив
    cacheTime: CACHE_TIME.M5,
  });
  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Получить новости по ручному тегу по проекту.
 * @param apiConfig - набор конфигов api;
 * @param projectId - ID вертикали, по которой необходим список;
 * @param tagAlias - алиас тега;
 * @param page - номер страницы;
 * @param limit - лимит количества новостей.
 */
export const getTagNewsByProject = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
  tagAlias: EditorType['alias'],
  page: number = 1,
  limit: 10 | 15 | 20 | 25 | 30 | 40 | 50 = 15,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/projects/${projectId}/tags/${tagAlias}/clusters/?limit=${limit}&page=${page}`,
    entrypoint: '/v1/projects/{projectId}/tags/{tagAlias}/clusters/',
    // Список кластеров меняется достаточно часто
    cacheTime: CACHE_TIME.M4,
  });
  const nextPage = getNextPage(response);

  return {
    ...response,
    data: {
      clusters: getApiCardList(response.data),
      pagination: {
        page,
        nextPage,
      },
    },
  };
};

/**
 * Получение информации по ручному тегу.
 * @param apiConfig - набор конфигов api;
 * @param tagAlias - alias тега.
 */
export const getTagInfo = async (
  apiConfig: ApiConfigType,
  tagAlias: string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/tags/${tagAlias}/`,
    entrypoint: '/v1/tags/{tagAlias}/',
    // Ручка не меняется так часто
    cacheTime: CACHE_TIME.M20,
  });

  return { ...response, data: getApiTagInfo(response.data) };
};

/**
 * Получение данных о локации пользователя
 * @param apiConfig - набор конфигов api;
 * @param geoId - id геопозии о которой нужна инфа.
 * @param signal - сигнал аборта запрос.
 */
export const getLocation = async (
  apiConfig: ApiConfigType,
  geoId: string,
  signal?: AbortSignal,
) => {
  const response = await getData<LocationData>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Rambler }),
    path: '/location/current',
    entrypoint: '/location/current',
    withLocalProxy: true,
    skipRedis: true,
    headers: {
      cookie: `geoid=${geoId}`,
    },
    signal,
    credentials: 'include',
  });

  return { ...response };
};

/**
 * Получение данных о локации пользователя без передачи geoId
 * Определяет местоположение пользователя автоматически.
 * Изменение геолокации в топлайне не влияет на ответ
 * @param apiConfig - набор конфигов api;
 * @param signal - сигнал аборта запрос.
 */
export const getLocationAuto = async (
  apiConfig: ApiConfigType,
  signal?: AbortSignal,
) => {
  const response = await getData<LocationData>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Rambler }),
    path: '/location/autodetect',
    entrypoint: '/location/autodetect',
    withLocalProxy: true,
    skipRedis: true,
    signal,
    credentials: 'include',
  });

  return { ...response };
};

/**
 * Получение данных о погоде.
 * @param apiConfig - набор конфигов api;
 * @param geoid - код погоды.
 */
export const getWeather = async (
  apiConfig: ApiConfigType,
  geoid: string | string[],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Rambler }),
    path: '/api/v4/weather/',
    entrypoint: '/api/v4/weather/',
    headers: {
      cookie: `geoid=${geoid}`,
    },
  });

  return { ...response };
};

/**
 * Получение данных о валютах.
 * @param apiConfig - набор конфигов api.
 */
export const getCurrencies = async (apiConfig: ApiConfigType) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Rambler }),
    path: '/api/v4/currencies/',
    entrypoint: '/api/v4/currencies/',
    cacheTime: 60 * 5,
  });

  return { ...response };
};

/**
 * Получение данных о топе по автотегу вертикали
 * @param apiConfig - набор конфигов api;
 * @param projectId - id вертикали;
 * @param autotagType - тип автотега.
 */
export const getAutotagPopular = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
  autotagType: ATAutotagInfo['autotagType'],
) => {
  const response = await getData({
    api: getAPIFromConfig({
      apiConfig,
      apiName: API_NAMES.Peroxide,
      timeout: 5000,
      clientTimeout: 5000,
    }),
    path: `/v1/projects/${projectId}/autotags/${autotagType}/popular/`,
    entrypoint: '/v1/projects/{projectId}/autotags/{autotagType}/popular/',
    cacheTime: 60,
  });

  return { ...response, data: getApiAutotagPopularList(response.data) };
};

/**
 * Получение количества комментариев для карточек кластеров
 * @param apiConfig - набор конфигов api;
 * @param clusterIDs - массив id кластеров
 * @returns массив объектов с id и количеством комментариев (если получение прошло успешно)
 */
export const getCommentsByClusterID = async (
  apiConfig: ApiConfigType,
  clusterIDs: (string | number)[],
) => {
  const response = await getData<CommentsType>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/comments/clusters/?${clusterIDs.reduce(
      (query, id) => `${query}cluster_ids[]=${id}&`,
      '',
    )}`,
    entrypoint: '/v1/comments/clusters/',
    withLocalProxy: true,
    skipRedis: true,
  });

  return { ...response };
};

/**
 * Получение срочной новости.
 * @param apiConfig - набор конфигов api;
 * @param projectId - id проекта, для которого запрашиваем срочную новость.
 */
export const getBreakingNews = async (
  apiConfig: ApiConfigType,
  projectId: ProjectType['id'],
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Coolstream }),
    path: `/breaking/current/?project_id=${projectId}`,
    entrypoint: '/breaking/current',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение индексов и котировок.
 * @param props - пропсы
 * @param props.apiConfig - набор конфигов api;
 * @param props.src - рынок для запроса;
 * @param props.period - период для запроса;
 * @param props.charCode - тикет для запроса;
 * @param props.limit - количество котировок;
 * @param props.needReverseOrder - флаг, нужен ли обратный порядок данных;
 * @param props.start - начало временного отрезка, за который запрашиваем информацию (в секундах);
 * @param props.end - конец временного отрезка (в секундах).
 */
export const getIndicesAndQuotes = async ({
  apiConfig,
  src,
  period,
  charCode,
  limit = 10,
  needReverseOrder = false,
  start,
  end,
}: {
  apiConfig: ApiConfigType;
  src: IndicesAndQuotesMarketsName;
  period: IndicesAndQuotesPeriod;
  charCode: IndicesAndQuotesCharCode | ExchangeRateDynamicsCurrency;
  limit?: number;
  needReverseOrder?: boolean;
  start?: number;
  end?: number;
}) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/dynamics/rate/?src=${src}&period=${period}&char_code=${charCode}&limit=${limit}&reverse_order=${needReverseOrder}${
      start && end ? `&start=${start}&end=${end}` : ''
    }`,
    entrypoint: '/v1/dynamics/rate/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение данных для курса обмена.
 * @param apiConfig - набор конфигов api;
 * @param charCode - валюта для запроса;
 * @param sort - продажа или покупка валюты для запроса.
 */
export const getBanksRates = async (
  apiConfig: ApiConfigType,
  charCode: BANKS_RATES_CURRENCY,
  sort: string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/banks_rates/?char_code=${charCode}&sort=${sort}&limit=10&region=Moscow`,
    entrypoint: '/v1/banks_rates/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение списка банков для определенного региона.
 * @param apiConfig - набор конфигов api;
 * @param region - регион для получения списка банков;
 * @param sort - продажа или покупка валюты для запроса.
 */
export const getBanksExchange = async (
  apiConfig: ApiConfigType,
  region: string,
  sort: string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/banks_rates/?sort=${sort}&limit=40&region=${region}`,
    entrypoint: '/v1/banks_rates/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение списка возможных кодов банков.
 * @param apiConfig - набор конфигов api.
 */
export const getBankCodes = async (apiConfig: ApiConfigType) => {
  const response = await getData<{ code: string }[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: '/v1/banks_codes/',
    entrypoint: '/v1/banks_codes/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение данных о банке.
 * @param apiConfig - набор конфигов api;
 * @param bankCode - имя банка для запроса.
 */
export const getBankData = async (
  apiConfig: ApiConfigType,
  bankCode: string,
) => {
  const response = await getData<APIBanks[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/banks_rates/${bankCode}/`,
    entrypoint: '/v1/banks_rates/{bankCode}/',
    withLocalProxy: true,
  });

  return { ...response };
};

const DEFAULT_OPTIONS: GetBankObjectsOptionsType = {
  page: 1,
  region: DEFAULT_REGION,
  limit: 50,
  objectType: undefined,
};

/**
 * Получение данных об объектах банка.
 * @param apiConfig - набор конфигов api;
 * @param bankCode - имя банка для запроса;
 * @param options - дополнительные и необязательные данные для запроса;
 * @param options.page - страница запроса;
 * @param options.limit - колво данных, выдаваемых в одном запросе;
 * @param options.region - регион, по которому выдаются данные;
 * @param options.objectType - тип отделений банка, которые надо выдавать.
 *  Если не указан, то выдаются все отделения.
 */
export const getBankObjects = async (
  apiConfig: ApiConfigType,
  bankCode: string,
  options: GetBankObjectsOptionsType = DEFAULT_OPTIONS,
) => {
  const page = `?page=${options.page ?? DEFAULT_OPTIONS.page}`;
  const limit = `&limit=${options.limit ?? DEFAULT_OPTIONS.limit}`;
  const region = `&region=${options.region ?? DEFAULT_OPTIONS.region}`;
  const objectType = options.objectType
    ? `&object_type=${options.objectType}`
    : '';

  const response = await getData<APIBankObjects[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/banks/code/${bankCode}/objects/${page}${limit}${region}${objectType}`,
    entrypoint: '/v1/banks/code/{bankCode}/objects/',
    withLocalProxy: true,
  });

  return { ...response, hasNextPage: getNextPage(response) !== '' };
};

/**
 * Получение данных о конкретном имени для виджета Что значит имя.
 * @param apiConfig - набор конфигов api;
 * @param nameAlias - имя на латинице.
 */
export const getHoroscopeName = async (
  apiConfig: ApiConfigType,
  nameAlias: string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Horoscopes }),
    path: `/api/front/v4/names/${nameAlias}/widget/`,
    entrypoint: '/api/front/v4/names/{nameAlias}/widget/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение кросс-курса валют.
 * @param apiConfig - набор конфигов api.
 */
export const getCrossRate = async (apiConfig: ApiConfigType) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: '/v1/instruments/?src=forex&instrument_type=crossrate',
    entrypoint: '/v1/instruments/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение списка регионов.
 * @param apiConfig - набор конфигов api.
 */
export const getBankRegions = async (apiConfig: ApiConfigType) => {
  const response = await getData<DefaultBankRegionsType[]>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Coolstream }),
    path: '/v2/bankregions/',
    entrypoint: '/v2/bankregions/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение прогноза курса по валюте.
 * @param apiConfig - набор конфигов api;
 * @param charCode - код валюты;
 * @param type - тип прогноза (неделя/месяц/год).
 */
export const getForecastsLast = async (
  apiConfig: ApiConfigType,
  charCode: CurrencyType['charCode'],
  type: FORECASTS_TYPE,
) => {
  const response = await getData<ForecastsLastType[] | []>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Uranyl }),
    path: `/v1/forecasts_last/?currency_char_code=${charCode}&forecast_type=${type}`,
    entrypoint: '/v1/forecasts_last/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение списка стран для путешествий.
 * @param apiConfig - набор конфигов api;
 * @param options - опции
 * @param options.limit - кол-во стран ожидаемое в ответе;
 * @param options.order - сортировка по имени или рэйтингу;
 * @param options.page - номер страницы для загрузки.
 */
export const getTravelCountries = async (
  apiConfig: ApiConfigType,
  options: {
    limit: number;
    order: 'name' | 'rating';
    page?: number;
  },
) => {
  const { limit, order, page } = options;

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Travel }),
    path: `/v2/travel_regions/?limit=${limit}&order_by=${order}&page=${page || 1}`,
    entrypoint: '/v2/travel_regions/',
    cacheTime: CACHE_TIME.H12,
    withLocalProxy: true,
    withRelativePath: true,
  });

  return {
    ...response,
    data: getApiTravelRegions(response.data.result),
    page: response.data.page,
  };
};

/**
 * Получение информаци по любому гео-объекту (страна, регион, город).
 * @see https://travel.rambler.ru/v2/travel_regions/GreatBritain/
 * @param apiConfig - набор конфигов api;
 * @param options - опции
 * @param options.regionAlias – alias страны/региона/города по которому получаем информацию
 */
export const getTravelRegionInfo = async (
  apiConfig: ApiConfigType,
  options: {
    regionAlias: string;
  },
) => {
  const { regionAlias } = options;

  const response = await getData<TravelRegionDataType>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Travel }),
    path: `/v2/travel_regions/${regionAlias}/`,
    entrypoint: '/v2/travel_regions/{regionAlias}/',
    cacheTime: CACHE_TIME.H12,
    withLocalProxy: true,
    withRelativePath: true,
  });

  return {
    ...response,
    data: getApiTravelRegionInfo(response.data),
  };
};

type GetTravelCitiesOptions = {
  limit: number;
  regionAlias: string;
  page?: number;
  order?: 'name' | 'rating';
  isCapitalFirst?: boolean;
  isCapitalExclude?: boolean;
};

/**
 * Получение списка городов стран для путешествий.
 * @param apiConfig - набор конфигов api;
 * @param options - опции
 * @param options.limit - кол-во стран ожидаемое в ответе;
 * @param options.page - кол-во стран ожидаемое в ответе;
 * @param options.regionAlias - алиас страны;
 * @param options.isCapitalFirst - флаг что столица страны в начале;
 * @param options.isCapitalExclude - отдавать или нет столицу в списке городов.
 */
export const getTravelCities = async (
  apiConfig: ApiConfigType,
  options: GetTravelCitiesOptions,
) => {
  const { limit, order, regionAlias, isCapitalFirst, isCapitalExclude, page } =
    options;

  const orderParam = order ? `&order_by=${order}` : '';
  const capitalFirstParam = isCapitalFirst ? `&is_capital=true` : '';
  const capitalExcludeParam = isCapitalExclude
    ? `&is_capital_exclude=true`
    : '';

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Travel }),
    path: `/v2/travel_regions/${regionAlias}/cities/?limit=${limit}&page=${page || 1}${orderParam}${capitalFirstParam}${capitalExcludeParam}`,
    entrypoint: '/v2/travel_regions/{regionAlias}/cities/',
    cacheTime: CACHE_TIME.H12,
    withLocalProxy: true,
    withRelativePath: true,
  });

  return { ...response, data: getApiTravelRegions(response.data?.result) };
};

type GetTravelPlacesOptions = {
  regionAlias: string;
  limit: number;
  categoryId?: 1 | 7 | 9 | 13 | 20 | 43;
  page?: number;
};

/**
 * Получение списка мест для региона путешествий.
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=43881377
 * @param apiConfig - набор конфигов api;
 * @param options - опции
 * @param options.limit - кол-во ожидаемое в ответе;
 * @param options.regionAlias - алиас страны или города;
 * @param options.page - номер страницы;
 * @param options.categoryId - id категории места.
 */
export const getTravelPlaces = async (
  apiConfig: ApiConfigType,
  options: GetTravelPlacesOptions,
) => {
  const { regionAlias, categoryId, limit, page } = options;

  const categoryParam = categoryId ? `&category_id=${categoryId}` : '';

  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Travel }),
    path: `/v2/travel_regions/${regionAlias}/points/?limit=${limit}&page=${page || 1}${categoryParam}`,
    entrypoint: '/v2/travel_regions/{regionAlias}/points/',
    cacheTime: CACHE_TIME.H12,
    withLocalProxy: true,
    withRelativePath: true,
  });

  if (!response.data) {
    return response;
  }

  return { ...response, data: getApiTravelPlaces(response.data.result) };
};

/**
 * Получение информации по региону
 * https://travel.rambler.ru/v2/travel_regions/GreatBritain/
 * @param apiConfig - набор конфигов api;
 * @param regionAlias - алиас региона.
 */
export const getTravelRegion = async (
  apiConfig: ApiConfigType,
  regionAlias: string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Travel }),
    path: `/v2/travel_regions/${regionAlias}/`,
    entrypoint: '/v2/travel_regions/{regionAlias}/',
    cacheTime: CACHE_TIME.H12,
    withLocalProxy: true,
    withRelativePath: true,
  });

  if (!response.data) {
    return response;
  }

  return { ...response, data: getApiTravelRegions([response.data])[0] };
};

/**
 * Получение данных для виджета гигачата.
 * @param apiConfig - набор конфигов api.
 */
export const getGigaChatData = async (apiConfig: ApiConfigType) => {
  const response = await getData<RamblerGigaChatData>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Peroxide }),
    path: `/v1/summarize/?top_type=project&alias=news`,
    entrypoint: '/v1/summarize/',
    withLocalProxy: true,
  });

  return { ...response };
};

/**
 * Получение места по id.
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=43881377
 * @param apiConfig - набор конфигов api;
 * @param pointId - id места.
 */
export const getTravelPlace = async (
  apiConfig: ApiConfigType,
  pointId: string,
) => {
  const response = await getData({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Travel }),
    path: `/v2/points/${pointId}/`,
    entrypoint: '/v2/points/{pointId}/',
    cacheTime: CACHE_TIME.H12,
    withLocalProxy: true,
    withRelativePath: true,
  });

  if (!response.data) {
    return response;
  }

  return { ...response, data: getApiTravelPlaces([response.data])[0] };
};

/**
 * Получение id кластера для редиректа со старых ньюсов
 * @see https://jira.rambler-co.ru/browse/NEWS-12002
 * @param apiConfig - набор конфигов api;
 * @param path - путь, с которого редиректим.
 */
export const getClusterForRedirect = async (
  apiConfig: ApiConfigType,
  path: string,
) => {
  const response = await getData<{ cluster_id?: number }>({
    api: getAPIFromConfig({ apiConfig, apiName: API_NAMES.Coolstream }),
    path: `/v1/clusters/redirect/?path=${path}`,
    entrypoint: '/v1/clusters/redirect/?path={path}',
    cacheTime: CACHE_TIME.H12,
    withLocalProxy: true,
    withRelativePath: true,
  });

  return { ...response, data: response.data?.cluster_id };
};
