import { RequestHandlerType } from './typings';

/**
 * Обработчик запроса для дополнительных манипуляций (сбора метрик).
 * Вынесен отдельно для различия между клиентом/сервером.
 * @param props - пропсы
 * @param props.request - функция запроса
 */
export const requestHandler: RequestHandlerType = async ({ request }) =>
  await request();
